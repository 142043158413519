import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Select } from "antd";
import axios from "axios";
import { Tooltip } from 'antd';
import {
  API_HEADER,
  BASE_DOCUMENT,
  get_client_name_url,
} from "../config";
import { get_scope_url } from "../config";
import { get_sectoralscope_url } from "../config";
import { get_program_url } from "../config";
import { get_country_url } from "../config";
import { get_assesment_url } from "../config";
import Header from "./Header";
import { get_project_details_url, get_scope_pa_poa_url, get_meths_url, get_contact_person_url } from "../config";
import SideNavbar from "../Components/SideNavbar";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import ClientDraftFinding from "../Components/ClientDraftFinding";
import { MultiSelect } from "react-multi-select-component";
import ClientSiteFinding from "../Components/ClientSiteFinding";
import ClientLODReview from '../Components/ClientLODReview';
import ClientTechReview from "../Components/ClientTechReview";
import ClientCCReview from "../Components/ClientCCReview";
import ActivityClientTL from "../Components/ActivityClientTL";
import ClientRfi from "../Components/ClientRfi";
import ClientSiteVisit from "../Components/ClientSiteVisit";


const ClientProjectDetails = ({ formdisabled }) => {
  const { id } = useParams();
  const { Option } = Select;

  const navigate = useNavigate();

  const designation_id = sessionStorage.getItem("designation_id");
  const [projectid, setProjectId] = useState(null);
  const [scopevalue, setScopeValue] = useState('');
  const [projectstatus, setProjectStatus] = useState();
  const [clientName, setClientName] = useState([]);
  const [loading, setLoading] = useState(true);
  const [myscope, setMyScope] = useState([]);
  const [program, setProgram] = useState([]);
  const [country, setCountry] = useState([]);
  const [meth, setMeth] = useState([])
  const [atlist, setAtList] = useState([]);
  const [f23name, setF23Name] = useState(null);
  const [formEdit, setformEdit] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [f23, setF23] = useState("");
  const [pddName, setPddName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setErName] = useState(null);
  const [er, setEr] = useState("");
  const [mrName, setMrName] = useState(null);
  const [mr, setMr] = useState("");
  const [scope_PA_POA, setScope_PA_POA] = useState([]);

  const [cpaName, setcpaName] = useState(null);
  const [cpa, setcpa] = useState("");

  const [lod, setlod] = useState("");
  const [lodName, setlodName] = useState(null);

  const [irrName, setirrName] = useState(null);
  const [irr, setIrr] = useState("");

  const [dynamicName, setDynamicName] = useState([]);
  const [dynamic, setDynamic] = useState([]);

  const [rfiReport, setRfiReport] = useState("");
  const [rfiReportDoc, setRfiReportDoc] = useState(null);

  const [formOpen, setformOpen] = useState(false);
  const [statusCode, setStatusCode] = useState(false);
  const [selected, setSelected] = useState([])
  const [mysectorscope, setMySectorScope] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [siteFindingStatus, setSiteFindingStatus] = useState(null);
  const [trStatus, setTrStatus] = useState(null);
  const [draftFindingStatus, setDraftFindingStatus] = useState(null);
  const [ccStatus, setCCStatus] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);

  const [clientContactPerson, setClientContactPerson] = useState([]);
  const [selectedclientContactPerson, setselectedclientContactPerson] = useState([])

  const [newRegistrarDocName, setNewRegistrarDocName] = useState(null)
  const [newRegistrarDoc, setNewRegistrarDoc] = useState('')

  const [newValidationDeedName, setNewValidationDeedName] = useState(null);
  const [newValidationDeed, setNewValidationDeed] = useState('');

  const [newVarificationDeedName, setNewVarificationDeedName] = useState(null);
  const [newVarificationDeed, setNewVarificationDeed] = useState('');

  const [newOtherDocName, setNewOtherDocName] = useState(null);
  const [newOtherDoc, setNewOtherDoc] = useState([]);

  const [apidata, setAPIData] = useState(null);
  const [siteVisitStatus, setSiteVisitStatus] = useState("");

  const [formData, setFormData] = useState({
    project_name: "",
    project_status: "",
    earthood_id: "",
    client_id: "",
    country: "",
    program: "",
    program_id: "",
    implemented_fees: "",
    created_at: "",
    scope: "",
    scope_pa: "",
    sectoral_scope: [],
    team_leader: "",
    ta_expert: "",
    validator_verifier: "",
    finance_expert: "",
    local_expert: "",
    meth_expert: "",
    trainee_validator: "",
    technical_reviewer: "",
    expert_tr: "",
    sbu_head: "",
    pdd_doc: "",
    er_doc: "",
    mr_doc: "",
    irr_doc: "",
    cpa_doc: "",
    evidence_name: [],
    files: [],
    contact_person_id: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${get_project_details_url}/${id}`,
          API_HEADER
        );
        const { record } = response.data;
        setProjectId(record.id);

        setAPIData(record);

        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${record.program}`
        );
        setAtList(responseteamleader.data.data);
        setSelectedClient(record.client_id);

        setFormData({
          project_name: record.project_name,
          project_status: record.project_status,
          earthood_id: record.earthood_id,
          client_id: record.client_id,
          country: record.country,
          program: record.program,
          program_id: record.program_id,
          implemented_fees: record.implemented_fees,
          scope: record.scope,
          scope_pa: record.scope_pa,
          team_leader: record.team_leader,
          sectoral_scope: selected,
          evidence_name: record.evidences || [],
          files: record.files || [],
          tl_remarks: record.tl_remarks,
          code: record.status,
          meth: record.meth,
          contact_person_id: record.contact_person_id
        });

        setTrStatus(record.tr_status)
        setSiteFindingStatus(record.site_finding_status)
        setDraftFindingStatus(record.finding_status)
        setCCStatus(record.cc_status);
        setSiteVisitStatus(record.site_visit_status);

        setformEdit(true)

        if (record.status == 1 || record.status == 4) {
          setformOpen(true);
        }

        if (record.status >= 4) {
          setStatusCode(true);
        }


        const sectorscopes = record.sectoral_scope.split(",").map(Number);
        const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
          sectorscopes.includes(sectorscope.value)
        );
        setSelectedSector(filteredSectorScopes);


        setScopeValue(record.scope_name.sector_name);
        setProjectStatus(record.status);

        let url3 = `${BASE_DOCUMENT}/documents/${record.earthood_id.split(' ')[0]}/${record.signed_contract}`;
        setF23Name(url3);
        setF23(record.signed_contract);

        let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.pdd_documents}`;
        setPddName(url4);
        setPdd(record.pdd_documents);

        let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.er_documents}`;
        setErName(url5);
        setEr(record.er_documents);

        let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.mr_documents}`;
        setMrName(url6);
        setMr(record.mr_documents);

        let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.irr_documents}`;
        setirrName(url7);
        setIrr(record.irr_documents);

        let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.cpa_documents}`;
        setcpaName(url8);
        setcpa(record.cpa_documents);

        let url0 = `${BASE_DOCUMENT}/projects/${record.id}/${record.lod_documents}`;
        setlodName(url0);
        setlod(record.lod_documents);

        let url10 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
        setRfiReportDoc(url10);
        setRfiReport(record.rfi_report);

        let url17 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
        setNewVarificationDeedName(url17);
        setNewVarificationDeed(record.verification_deed);

        let url18 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
        setNewValidationDeedName(url18);
        setNewValidationDeed(record.validation_deed);

        let url19 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
        setNewRegistrarDocName(url19);
        setNewRegistrarDoc(record.rfi_report);

        let otherDocsUrls = record.rfi_registrar_docs.split(',').map(doc => `${BASE_DOCUMENT}/projects/${record.id}/${doc.trim()}`);

        let otherDocsNames = record.rfi_registrar_docs.split(',');

        setNewOtherDocName(otherDocsUrls);
        setNewOtherDoc(otherDocsNames);


        record.evidences.forEach((evidence, index) => {
          let dynamicurl = `${BASE_DOCUMENT}/projects/${record.id}/${evidence.name}`;
          setDynamicName(dynamicurl);
          setDynamic(evidence.name);
        });

      } catch (error) { }
    };

    fetchData(id);
  }, [id, mysectorscope, myscope]);

  const combined = newOtherDoc?.map((name, index) => ({
    name: name,
    url: newOtherDocName[index]
  }));

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  useEffect(() => {
    const fetchDataClientName = async () => {
      try {
        const responseclientname = await axios.get(`${get_client_name_url}`);
        setClientName(responseclientname.data.data);
      } catch (error) { }
    };

    fetchDataClientName();
  }, []);

  useEffect(() => {
    const fetchSectoralScope = async () => {
      try {
        const responsesectoralscope = await axios.get(`${get_sectoralscope_url}`);

        setMySectorScope(
          responsesectoralscope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.scope,
          }))
        );
      } catch (error) {

      }
    };

    fetchSectoralScope();
  }, []);


  useEffect(() => {
    const fetchDataScope = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_url}`);

        setMyScope(responsescope.data.data);
      } catch (error) { }
    };

    fetchDataScope();
  }, []);

  useEffect(() => {
    const fetchDataProgram = async () => {
      try {
        const responseprogram = await axios.get(`${get_program_url}`);
        setProgram(responseprogram.data.data);
      } catch (error) { }
    };

    fetchDataProgram();
  }, []);

  useEffect(() => {
    const fetchDataSope_PA_POA = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

        setScope_PA_POA(responsescope.data.data);
      } catch (error) { }
    };

    fetchDataSope_PA_POA();
  }, []);

  useEffect(() => {
    const fetchDataCountry = async () => {
      try {
        const responsecountry = await axios.get(`${get_country_url}`);
        setCountry(responsecountry.data.data);
      } catch (error) { }
    };

    fetchDataCountry();
  }, []);

  useEffect(() => {
    const fetchDataTechnicalReviewer = async () => {
      try {
        const responseteamleader = await axios.get(`${get_assesment_url}`);
        setAtList(responseteamleader.data.data);
      } catch (error) { }
    };

    fetchDataTechnicalReviewer();
  }, []);

  useEffect(() => {
    const fetchDataMeth = async () => {
      try {
        const responsemeths = await axios.get(`${get_meths_url}`);
        setMeth(responsemeths.data.data);
      } catch (error) { }
    };

    fetchDataMeth();
  }, []);

  useEffect(() => {
    const fetchDataClientContactPerson = async () => {
      try {
        let payload = {
          id: selectedClient
        }
       
        const responseclientcontactperson = await axios.post(`${get_contact_person_url}`, payload);
        const formattedClientContactPerson = JSON.parse(responseclientcontactperson.data.data.contact_person).map((person) => ({
          value: person.id,
          label: person.name,
        }));
        
        setClientContactPerson(formattedClientContactPerson);
        
        const contactPersonId = apidata.contact_person_id ? String(apidata.contact_person_id) : '';
        const sectorscopes = contactPersonId.split(",").map(Number);
        const filteredSectorScopes = formattedClientContactPerson.filter((sectorscope) =>
          sectorscopes.includes(sectorscope.value)
        );
        
        setselectedclientContactPerson(filteredSectorScopes);
        

      } catch (error) { }
    };
    if (selectedClient) {
      fetchDataClientContactPerson();
    }
  }, [selectedClient]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid bg-white">
            <div className="row pb-5 ">
              <div className="col-12 ">
                <h4 className="mx-4 textcolorblue fw-bolder p-2 text-capitalize mt-4">
                  Project Details
                </h4>
                <Tabs
                  defaultActiveKey="1"
                  className="mx-5"
                  activeKey={activeTab}
                  onChange={handleTabChange}
                  indicator={{ Backgroundcolor: "#07B6AF" }}
                  size="medium"
                >
                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 text-center fs-6 ${activeTab == 1 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Project Details</p>
                      </div>
                    }
                    key="1"
                  >
                    <form>
                      <fieldset disabled={formEdit}>
                        <div className="col-12 border-0 py-1 mx-auto">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="projectname"
                                className="form-label lightgreen fs-6"
                              >
                                Project Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Tooltip title={formData.project_name} placement="top">
                                <input
                                  type="text"
                                  className="form-control borderlightgreen"
                                  id="project_name"
                                  placeholder="Project Name"
                                  required
                                  readOnly={true}
                                  name="project_name"
                                  value={formData.project_name}
                                />
                              </Tooltip>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="eid"
                                className="form-label lightgreen fs-6"
                              >
                                Earthood Id
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control borderlightgreen"
                                id="earthood_id"
                                placeholder="Earthood Id"
                                name="earthood_id"
                                required
                                readOnly={true}
                                value={formData.earthood_id}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="clientname"
                                className="form-label lightgreen fs-6"
                              >
                                Client Name
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="client_id"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="client_id"
                                readOnly={true}
                                value={formData.client_id}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {clientName.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                          </div>

                          <div className="row">

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="contact person"
                                  className="form-label lightgreen fs-6"
                                >
                                  Client Contact Person
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                       
                              <MultiSelect
                                options={clientContactPerson}
                                value={selectedclientContactPerson}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />


                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="country"
                                className="form-label lightgreen fs-6"
                                required
                              >
                                Project Country<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="country"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="country"
                                readOnly={true}
                                value={formData.country}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {country.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program"
                                className="form-label lightgreen fs-6"
                              >
                                Program<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="program"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="program"
                                readOnly={true}
                                value={formData.program}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {program.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.program_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program Id"
                                className="form-label lightgreen fs-6"
                              >
                                Program Id
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="program_id"
                                placeholder="Program Id"
                                required
                                name="program_id"
                                readOnly={true}
                                className="form-control borderlightgreen"
                                value={formData.program_id}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Implementation Fees"
                                className="form-label lightgreen fs-6"
                              >
                                Implementation Fees
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control borderlightgreen"
                                id="implemented_fees"
                                placeholder="Implementation Fees"
                                required
                                readOnly={true}
                                name="implemented_fees"
                                value={formData.implemented_fees}
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Scope<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="scope"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope"
                                readOnly={true}
                                value={formData.scope}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {myscope.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.sector_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope(PO/POA)"
                                className="form-label lightgreen fs-6"
                              >
                                Scope(PA/POA)
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="scope_pa"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope_pa"
                                value={formData.scope_pa}
                                disabled={true}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    scope_pa: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {scope_PA_POA.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.scope}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label htmlFor="Scope" className="form-label lightgreen fs-6">
                                Sectoral Scope<span style={{ color: "red" }}>*</span>
                              </label>
                              <MultiSelect
                                options={mysectorscope}
                                value={selectedSector}
                                onChange={setSelectedSector}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Team Leader
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="team_leader"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="team_leader"
                                required
                                value={formData.team_leader}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id === 1)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>

                          </div>

                          <div className="row">

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Meth"
                                className="form-label lightgreen fs-6"
                              >
                                Meth

                              </label>
                              <select
                                id="meth"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="meth"
                                value={formData.meth}
                              >
                                <option value={""}>Select</option>
                                {meth?.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>


                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Service Agreement (F23)
                              </label>
                              <div>
                                <a target="_blank" href={f23name}>
                                  {f23}
                                </a>
                              </div>
                            </div>

                            {formData.project_status >= 10 ?
                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="taexpert"
                                  className="form-label lightgreen fs-6"
                                >
                                  RFI Report
                                </label>
                                <div>
                                  <a target="_blank" href={rfiReportDoc}>
                                    {rfiReport}
                                  </a>
                                </div>
                              </div>
                              : ''}

                          </div>
                          {formData.project_status >= 10 ?



                            <div className="row">
                              {newValidationDeed &&

                                <div className="col-lg-4 col-md-4 col-12 mb-3">
                                  <label
                                    htmlFor="taexpert"
                                    className="form-label lightgreen fs-6"
                                  >
                                    Validation Deed
                                  </label>
                                  <div>
                                    <a target="_blank" href={newValidationDeedName}>
                                      {newValidationDeed}
                                    </a>
                                  </div>
                                </div>
                              }

                              {newVarificationDeed &&

                                <div className="col-lg-4 col-md-4 col-12 mb-3">
                                  <label
                                    htmlFor="taexpert"
                                    className="form-label lightgreen fs-6"
                                  >
                                    Verification Deed
                                  </label>
                                  <div>
                                    <a target="_blank" href={newVarificationDeedName}>
                                      {newVarificationDeed}
                                    </a>
                                  </div>
                                </div>
                              }
                              {(newOtherDoc.length > 0) &&


                                <div className="col-lg-4 col-md-4 col-12 mb-3">
                                  <label
                                    htmlFor="taexpert"
                                    className="form-label lightgreen fs-6"
                                  >
                                    Other Docs
                                  </label>
                                  <div >
                                    {/* <a target="_blank" href={newOtherDocName}>
                                        {newOtherDoc}
                                      </a> */}

                                    {combined.map((item) => (
                                      <div>
                                        <a target="_blank" href={item.url}>
                                          {item.name}

                                        </a>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              }

                            </div>


                            : ''}


                        </div>
                      </fieldset>
                    </form>
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 2 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Projects Documents</p>
                      </div>
                    }
                    key="2"
                  >
                    <ClientLODReview />
                  </Tabs.TabPane >

                  {formData.project_status > 2 && draftFindingStatus >= 1 ?
                    <Tabs.TabPane tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 4 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Desk Review</p>
                      </div>
                    }
                      key="4">
                      <ClientDraftFinding />
                    </Tabs.TabPane>
                    : ''}


                    {siteVisitStatus == 12 ?

                      <Tabs.TabPane
                          tab={
                            <div
                              className={`textlightgreen rounded-0  py-2 text-center ${activeTab == 11 ? "box_sh" : ""
                                }`}
                            >
                              <p className="font20px">Site Visit</p>
                            </div>
                          }
                          key="11"
                        >

                       <ClientSiteVisit/>

                      </Tabs.TabPane>
                   
                    : ''}


                  {
                    siteFindingStatus >= 1 ?
                      <Tabs.TabPane tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 5 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">Site Visit Findings</p>
                        </div>
                      }
                        key="5">
                        <ClientSiteFinding />
                      </Tabs.TabPane>
                      :
                      ""
                  }


                  {formData.project_status > 6 && trStatus != 1 ?
                    <Tabs.TabPane tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 6 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Tech. Review</p>
                      </div>
                    }
                      key="6">
                      <ClientTechReview />
                    </Tabs.TabPane>
                    :
                    ""
                  }


                  {formData.project_status > 7 && trStatus === 8 && ccStatus >= 1 ?
                    <Tabs.TabPane tab={
                      <div
                        className={`textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 7 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Consistency Checker</p>
                      </div>
                    }
                      key="7">
                      <ClientCCReview />
                    </Tabs.TabPane>
                    :
                    ""
                  }


                  {formData.project_status > 8 &&
                    designation_id == 13 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 8 ? "box_sh" : ""
                            }`}
                        >
                          <p className="font20px">RFI / RFR</p>
                        </div>
                      }
                      key="8"
                    >
                      <ClientRfi />
                    </Tabs.TabPane>
                  ) : (
                    ""
                  )}



                  <Tabs.TabPane
                    tab={
                      <div
                        className={`textlightgreen rounded-0  py-2 fs-6 text-center  ${activeTab == 3 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Summary</p>
                      </div>
                    }
                    key="3"
                  >
                    <div className="col-12 border-0 bg-white ">
                      <ActivityClientTL id={projectid} />
                    </div>
                  </Tabs.TabPane>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default ClientProjectDetails;
